:root {
  --cp-blue-100: hsl(209, 100%, 25%);
  --cp-blue-200: hsl(209, 100%, 19%);

  --bs-blue: var(--cp-blue-100);
  --bs-gray-100: #F7F7F7;
  --bs-gray-200: #E8E8E8;
  --bs-gray-300: #C2C6C9;
}

.navbar + .container-fluid > .row {
  min-height: 100vh;
  padding-top: 61px;
}

.text-primary {
  color: var(--bs-blue) !important;
}

.border-primary {
  border-color: var(--bs-blue) !important;
}

body {
  font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body,
.bg-light {
  background-color: var(--bs-gray-100) !important;
}

.bg-primary {
  background-color: var(--bs-blue) !important;
  color: var(--bs-white);
}

.bg-white {
  background-color: var(--bs-white);
  color: var(--bs-body-color);
}

.navbar {
  padding-bottom: 10px;
  padding-top: 10px;
}

.navbar-brand {
  padding: 0;
}

.btn {
  border-radius: 3px;
}

.btn-primary {
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
  border-width: 2px;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: var(--bs-blue);
}

.btn-primary:hover,
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--cp-blue-200);
  border-color: var(--cp-blue-200);
  color: #fff;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  background-color: var(--cp-blue-200);
  border-color: var(--cp-blue-200);
}

.btn-outline-primary {
  border-color: var(--bs-blue);
  border-width: 2px;
  color: var(--bs-blue);
}

.btn-outline-primary:hover,
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
  color: #fff;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
  box-shadow: 0 0 0 0.25rem rgb(0 65 129 / 50%);
  color: #fff;
}

.link-primary {
  color: var(--bs-blue);
}

.form-check-input:checked {
    background-color: var(--bs-blue);
    border-color: var(--bs-blue);
}

.form-control {
  background-color: var(--bs-gray-100);
}

.btn-link {
  text-decoration: none;
}

.btn-light {
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-200);
  color: var(--bs-blue);
}

.btn-light:hover,
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}

.form-control {
  border-color: var(--bs-gray-200);
}

.modal-header {
  border-bottom-color: var(--bs-gray-200);
}

hr,
.vr {
  color: var(--bs-gray-200);
}

hr {
  height: 2px !important;
}

.vr {
  width: 2px !important;
}

.ratio-2x1 {
  --bs-aspect-ratio: calc(1 / 2 * 100%);
}

button {
  transition: background-color 500ms ease-in;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

[contenteditable]:focus {
  outline-color: var(--cp-blue-100);
}

[contenteditable] {
  padding: 0.5rem;
}

.list-group-item + .list-group-item.active {
  margin-top: 0;
}

.list-group-item.active {
  background-color: var(--bs-white);
}

.list-group-item.active,
.list-group-item.selected {
  background-color: var(--bs-gray-100);
  color: var(--bs-dark);
}

.list-group-item table {
  font-size: 0.7em;
  width: 100%;
}

.list-group-item table td {
  padding-right: 0.4em;
}

.error-message {
  max-height: 10vh;
}

.configured-module {
  transition: all 50ms linear;
}

.configured-module-enter {
  max-height: 0;
  opacity: 0;
}

.configured-module-enter-active {
  max-height: 111px;
  opacity: 1;
  transition: all 2500ms ease-out;
}

.insert-selected-module-exit {
  max-height: 100px;
  opacity: 1;
}

.insert-selected-module-exit-active {
  max-height: 0;
  opacity: 0;
  transition: all 2500ms ease-out;
}

.icon-check {
  bottom: 5px;
  left: 5px;
  stroke-width: 1px;
}

.big-modal.modal-dialog {
  max-width: 90%;
}

@media (min-width: 1400px) {
  .big-modal.modal-dialog {
    max-width: 80%;
  }
}
